import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Hr, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Our Services at Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"At Shenzhen Starnoon Technology Co., LTD, we offer a comprehensive range of services to transform your indoor spaces with innovative ceiling solutions. "} />
			<meta property={"og:title"} content={"Our Services | Our Services at Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"At Shenzhen Starnoon Technology Co., LTD, we offer a comprehensive range of services to transform your indoor spaces with innovative ceiling solutions. "} />
			<meta property={"og:image"} content={"https://vernioliever.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vernioliever.com/img/9119630.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vernioliever.com/img/9119630.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vernioliever.com/img/9119630.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vernioliever.com/img/9119630.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://vernioliever.com/img/5.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="40px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Our Services
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Services Overview
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						At Shenzhen Starnoon Technology Co., LTD, we offer a comprehensive range of services to transform your indoor spaces with innovative ceiling solutions. Our services are tailored to meet your specific needs and aesthetic preferences.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="30px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Custom Design Solutions:
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Bespoke Ceiling Designs – Tailored designs that reflect your personal style or brand identity.
								<br />
								<br />
								3D Rendering – Visualize your space with advanced 3D rendering technology for a realistic preview of the final design.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Stretch Ceiling Installation:
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Variety of Textures and Colors – Choose from a wide range of textures and colors to match your interior design theme.
								<br />
								<br />
								Lighting Integration – Seamlessly integrate lighting fixtures for a complete and functional ceiling design.
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								False Ceiling Solutions:
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Acoustic Optimization – Enhance room acoustics with strategically designed false ceilings.
								<br />
								<br />
								Energy Efficiency – Improve thermal insulation and energy efficiency of your space.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Eco-Friendly Options:
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Sustainable Materials – Utilize eco-friendly materials that reduce environmental impact without compromising quality.
								<br />
								<br />
								Recyclable Products – Choose from products that are not only durable but also recyclable at the end of their lifecycle.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://vernioliever.com/img/6.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
					/>
					<Image
						src="https://vernioliever.com/img/7.jpg"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 56px/1.2 --fontFamily-serifGaramond"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Additional Information
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="center">
						Consultation Services: Our experts are available for comprehensive consultations to discuss your needs and provide personalized recommendations.
						<br />
						After-Sales Support: We provide robust after-sales support, ensuring your satisfaction with our services.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 200px" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Learn about the array of ceiling solutions offered by Shenzhen Starnoon Technology Co., LTD – from custom designs to eco-friendly options and professional installations
			</Text>
			<Button
				font="--lead"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
				margin="20px"
				background="--color-indigo"
				border-radius="8px"
			>
				Contact Us
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});